function isOnProduction() {
	const isLocalHost = window.location.href.includes('localhost')
	const isHomolog = window.location.href.includes('homolog')
	const isIP = window.location.href.includes('192.')

	return !isLocalHost && !isHomolog && !isIP
}

export default isOnProduction = isOnProduction()

function currentUrl() {
	const isLocalHost = window.location.href.includes('localhost')
	const isHomolog = window.location.href.includes('homolog')
	const isDevelop = window.location.href.includes('develop')
	const isIP = window.location.href.includes('192.')
	const isVite = window.location.href.includes('127.')

	if (isHomolog || isIP || isVite || isLocalHost)
		return 'https://api-sgapp.seagatecentral.com.br/api'
	else if (isDevelop)
		return 'https://api-sgapp.seagatecentral.com.br/api'
	else
		return 'https://api-sgapp.seagatecentral.com.br/api'

}

export const apiUrlChecked = currentUrl()